<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="_blog_posts"
      :search="search"
      :items-per-page="10"
      class="br-16 elevation-1"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center br-16 pa-4">
          <v-text-field
            v-model="search"
            class="br-8"
            placeholder="Filter news"
            label="Title"
            style="max-width: 350px"
            prepend-inner-icon="search"
            hide-details
            outlined
            dense
          />
          <v-btn
            color="yellow"
            rounded
            :to="{
              name: 'admin-brands-id-new-news',
              params: { brand_id: selected_brand.id },
            }"
            class="mr-0"
          >
            <v-icon color="black" class="mr-2">mdi-plus</v-icon>Add new
            post</v-btn
          >
        </div>
      </template>

      <template v-slot:[`item.is_active`]="{ item }">
        <v-switch
          class="d-inline-block"
          dense
          :true-value="true"
          :input-value="item.is_active"
          :loading="loading_id === item.id"
          :disabled="loading_id === item.id"
          @change="(v) => update_blog_post(item.id, { is_active: v })"
        ></v-switch>
      </template>
      <template v-slot:[`item.publish_at`]="{ item }">
        {{ moment.utc(item.publish_at).format("DD/MM/YYYY HH:mm") }}
      </template>
      <template v-slot:[`item.expire_at`]="{ item }">
        {{ get_expiration_date_text(item.expire_at) }}
      </template>
      <template v-slot:[`item.preview`]="{ item }">
        <a
          class="no-text-decoration"
          target="_blank"
          :href="`${selected_brand.url}news/${item.id}/${item.slug}`"
        >
          <v-icon>mdi-eye-outline</v-icon>
        </a>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <router-link
          class="no-text-decoration"
          :to="{
            name: 'admin-brands-id-new-news',
            params: { id: selected_brand.id },
            query: { id: item.id },
          }"
        >
          <v-icon>mdi-pencil</v-icon>
        </router-link>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn
          icon
          @click="(selected_blog_post = item), (remove_blog_post_dialog = true)"
        >
          <v-icon color="red darken-1">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <AdminDialog
      v-if="remove_blog_post_dialog"
      :show_dialog.sync="remove_blog_post_dialog"
      header="Are you sure?"
      @confirm="delete_blog_post(selected_blog_post)"
      :loading="is_deleting"
      confirm_text="Delete"
      confirm_color="red"
    >
      <template v-slot:[`content`]>
        Do you want to delete selected blog post?
      </template>
    </AdminDialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";

import { get_blog_posts, upsert_blog_post, delete_blog_post } from "@/requests";
import { get_expiration_date_text } from "@/utils/date_utils";

import AdminDialog from "@/components/base/AdminDialog";

export default {
  name: "BrandNews",
  components: {
    AdminDialog,
  },
  data() {
    return {
      moment,
      get_expiration_date_text,
      loading_id: null,
      is_deleting: false,
      selected_blog_post: null,
      remove_blog_post_dialog: false,
      blog_posts: [],
      search: null,
      headers: [
        { text: "Title", value: "title" },
        { text: "Author", value: "author" },
        { text: "Published", value: "is_active", align: "center" },
        { text: "Publish At", value: "publish_at" },
        { text: "Expires At", value: "expire_at" },
        { text: "Preview", value: "preview", align: "center", sortable: false },
        { text: "Edit", value: "edit", align: "center", sortable: false },
        { text: "Delete", value: "delete", align: "center", sortable: false },
      ],
    };
  },
  async mounted() {
    const { data: blog_posts } = await get_blog_posts({
      brand_id: this.selected_brand.id,
    });
    this.blog_posts = blog_posts;
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
    _blog_posts() {
      if (this.search) {
        return this.blog_posts.filter((p) => p.name.includes(this.search));
      }
      return this.blog_posts;
    },
  },
  methods: {
    async update_blog_post(blog_post_id, updates) {
      this.loading_id = blog_post_id;
      const post_idx = this.blog_posts.findIndex(
        (post) => post.id === blog_post_id
      );
      const { data: updated_post } = await upsert_blog_post({
        ...this.blog_posts[post_idx],
        ...updates,
      });
      this.blog_posts[post_idx] = updated_post;
      this.loading_id = null;
    },
    async delete_blog_post(blog_post) {
      await delete_blog_post(blog_post.id);
      const { data: blog_posts } = await get_blog_posts({
        brand_id: this.selected_brand.id,
      });
      this.blog_posts = blog_posts;
      this.remove_blog_post_dialog = false;
      this.selected_blog_post = null;
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"br-16 elevation-1",attrs:{"headers":_vm.headers,"items":_vm._blog_posts,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center br-16 pa-4"},[_c('v-text-field',{staticClass:"br-8",staticStyle:{"max-width":"350px"},attrs:{"placeholder":"Filter news","label":"Title","prepend-inner-icon":"search","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mr-0",attrs:{"color":"yellow","rounded":"","to":{
            name: 'admin-brands-id-new-news',
            params: { brand_id: _vm.selected_brand.id },
          }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("mdi-plus")]),_vm._v("Add new post")],1)],1)]},proxy:true},{key:"item.is_active",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{staticClass:"d-inline-block",attrs:{"dense":"","true-value":true,"input-value":item.is_active,"loading":_vm.loading_id === item.id,"disabled":_vm.loading_id === item.id},on:{"change":function (v) { return _vm.update_blog_post(item.id, { is_active: v }); }}})]}},{key:"item.publish_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment.utc(item.publish_at).format("DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.expire_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_expiration_date_text(item.expire_at))+" ")]}},{key:"item.preview",fn:function(ref){
          var item = ref.item;
return [_c('a',{staticClass:"no-text-decoration",attrs:{"target":"_blank","href":((_vm.selected_brand.url) + "news/" + (item.id) + "/" + (item.slug))}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}},{key:"item.edit",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
          name: 'admin-brands-id-new-news',
          params: { id: _vm.selected_brand.id },
          query: { id: item.id },
        }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.delete",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){(_vm.selected_blog_post = item), (_vm.remove_blog_post_dialog = true)}}},[_c('v-icon',{attrs:{"color":"red darken-1"}},[_vm._v("mdi-delete")])],1)]}}],null,true)}),(_vm.remove_blog_post_dialog)?_c('AdminDialog',{attrs:{"show_dialog":_vm.remove_blog_post_dialog,"header":"Are you sure?","loading":_vm.is_deleting,"confirm_text":"Delete","confirm_color":"red"},on:{"update:show_dialog":function($event){_vm.remove_blog_post_dialog=$event},"confirm":function($event){return _vm.delete_blog_post(_vm.selected_blog_post)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Do you want to delete selected blog post? ")]},proxy:true}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }